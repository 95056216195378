import { GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';

import dayjs from 'dayjs';
import { EventService } from '@services/content';

import { ICardProps } from '@components/core/CardContent';
import { IEvent } from '@models/event.interface';
import MobileHome from '@components/pages/home/MobileHome';
import { useUser } from '@hooks/user';
import { HiddenSm } from '@components/ui';
import { useEffect, useState } from 'react';
import { getStories } from '@services/content/stories';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { getEventSpeakers } from '@services/squads';

const LoggedHome = dynamic(() => import('@components/pages/home/LoggedHome'));
const UnloggedHome = dynamic(
  () => import('@components/pages/home/UnloggedHome'),
);

export interface AllHomeProps {
  stories?: ICardProps[];
  liveEvents: IEvent[];
  futureEvents: IEvent[];
  liveSpeakers: any[];
  futureSpeakers: any[];
}

interface HomeProps {
  liveEvents: IEvent[];
  futureEvents: IEvent[];
  liveSpeakers: any[];
  futureSpeakers: any[];
}

const Home: NextPage<HomeProps> = props => {
  const { user } = useUser();
  const [stories, setStories] = useState<ICardProps[] | undefined>();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const init = async () => {
    const storiesFormatted = await getStories();
    setStories(storiesFormatted);
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {isMobile && <MobileHome {...props} stories={stories} />}
      <HiddenSm>
        {user?._id ? (
          <LoggedHome {...props} stories={stories} />
        ) : (
          <UnloggedHome {...props} stories={stories} />
        )}
      </HiddenSm>
    </>
  );
};

const getSpeakers = async events => {
  let speakers = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const event of events) {
    // eslint-disable-next-line no-await-in-loop
    const speaker = await getEventSpeakers(event.id);
    speakers = [...speakers, speaker];
  }
  return speakers;
};

export const getStaticProps: GetStaticProps = async () => {
  const events = await EventService.findAll();

  const liveEvents = events.filter(
    e =>
      dayjs(e.endedAt).isAfter(dayjs()) && dayjs(e.startedAt).isBefore(dayjs()),
  );
  const futureEvents = events.filter(e => dayjs(e.startedAt).isAfter(dayjs()));

  const liveSpeakers = await getSpeakers(liveEvents);
  const futureSpeakers = await getSpeakers(futureEvents);

  return {
    props: {
      liveEvents,
      futureEvents,
      liveSpeakers,
      futureSpeakers,
    },
    revalidate: 600,
  };
};

export default Home;
